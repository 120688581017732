export const state = () => ({
  query: ``,
  results: [],
  chosenResult: null,
})

export const getters = {
  results: (state, payload) => state.results,
}

export const mutations = {
  setResults(state, results) {
    state.results = results 
  }
}

export const actions = {
  async getResults({ commit }, payload) {
    const data = await this.$api.inputSearcher.getResults(payload.url, payload.query)
    commit(`setResults`, data)
  }
}
