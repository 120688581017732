import HttpFactory from '../factory'

class DASignerModule extends HttpFactory {
  async getDeclarations(code) {
    return await this.call(`get`, `/declaration_of_authorisation/declarations/${code}`)
  }

  async generateDA(data) {
    return await this.call(`post`, `/declaration_of_authorisation/generate`, data);
  }

}

export default DASignerModule
