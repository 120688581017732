export const state = () => ({
  query: ``,
  declarations: {
    name: ``,
    code: ``,
    declarations: [],
  },
})

export const getters = {
  declarations: (state, url) => state.declarations,
}

export const mutations = {
  setDeclarations(state, declarations) {
    state.declarations = declarations 
  }
}

export const actions = {
  async getDeclarations({ commit }, url) {
    const data = await this.$api.daSigner.getDeclarations(url)
    commit(`setDeclarations`, data)
  },

  async generateDA({ commit }, data) {
    return await this.$api.daSigner.generateDA(data);
  }
}
