
export default {
  name: `RatingModal`,
  props: {
    show: {
      type: Boolean,
      required: false
    },
  },
  data() {
    return {
      localShow: this.show,
      score: 0,
      comment: ``,
    };
  },
  watch: {
    show(newVal) {
      this.localShow = newVal;
    },
    localShow(newVal) {
      if (!newVal) {
        this.sendFeedback();
        console.log(`UPDATE SHOW`)
        this.$emit(`update:localShow`, newVal);
      }
    }
  },
  methods: {
    close() {
      this.localShow = false;
    },
    async sendFeedback() {
      await this.$api.rating.sendFeedback({
        score: this.score,
        comment: this.comment,
      });
    },
  }
};
